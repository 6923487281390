<template>
    <search-form-layout
        data-cy="hotel-search-form"
        :popup="popup"
        :title="productName"
        :width="isIframe ? '100%' : undefined"
        @close-popup="$emit('close-popup')"
    >
        <template v-slot:form>
            <v-form ref="form" v-model="valid" data-cy="hotels-form">
                <v-fade-transition v-if="!isSingleHotelSearch" mode="out-in">
                    <div v-if="isNotWorldSearch && productArea" key="israel-mode">
                        <v-fade-transition mode="out-in">
                            <v-row v-if="!isFreeSearch" key="not-free-search">
                                <v-col :cols="12" :md="4">
                                    <v-autocomplete
                                        v-model="selectedAreaCity"
                                        single-line
                                        filled
                                        return-object
                                        hide-no-data
                                        :placeholder="$t('tours.destination_location')"
                                        item-text="cityName"
                                        hide-details="auto"
                                        :clearable="true"
                                        background-color="white"
                                        :loading="loading"
                                        :items="productAreaCities"
                                        :prepend-inner-icon="'mdi-map-marker'"
                                        :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                                        @change="changeSelectedCity"
                                    />
                                </v-col>
                                <v-col :cols="12" :md="6">
                                    <v-autocomplete
                                        v-model="selectedAreaCityHotel"
                                        :disabled="!selectedAreaCity"
                                        single-line
                                        hide-no-data
                                        hide-details="auto"
                                        :placeholder="
                                            selectedAreaCity ? $t('select_hotel') : `${$t('search_form.all_hotels')}`
                                        "
                                        :prepend-inner-icon="'mdi-office-building'"
                                        item-text="name"
                                        return-object
                                        filled
                                        :clearable="true"
                                        :background-color="!selectedAreaCity ? 'rgba(255,255,255,0.7)' : 'white'"
                                        :items="productAreaCityHotels"
                                        @change="changeSelectedHotel"
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-else key="free-search">
                                <v-col :cols="12" :md="10">
                                    <city-hotel-autocomplete
                                        ref="locationAutocomplete"
                                        v-model="city"
                                        single-line
                                        filled
                                        :label="'search_form.city_region_hotel'"
                                        :country-id="$config.att_israelCountryId"
                                        :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                                        :default-cities="popularDestinationsForIsrael"
                                    />
                                </v-col>
                            </v-row>
                        </v-fade-transition>
                        <v-row class="mb-2">
                            <v-col :cols="12">
                                <span
                                    class="cursor-pointer"
                                    style="text-decoration: underline dashed"
                                    @click="isFreeSearch = !isFreeSearch"
                                >
                                    {{
                                        `${isFreeSearch ? $t('hide') : $t('show')}   ${$t('search_form.free_search')} `
                                    }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-else key="world-mode">
                        <v-col md="10" cols="12">
                            <city-hotel-autocomplete
                                ref="locationAutocomplete"
                                v-model="city"
                                single-line
                                :label="'search_form.city_region_hotel'"
                                :exclude-country-id="$config.att_israelCountryId"
                                filled
                                :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                                :default-cities="popularDestinations"
                            />
                        </v-col>
                    </v-row>
                </v-fade-transition>
                <v-row>
                    <v-col :md="4" cols="12">
                        <daterange-m :start-date.sync="startDate" :end-date.sync="endDate" />
                    </v-col>
                    <v-col :md="isCitizenshipMainOption ? 4 : 6" cols="12">
                        <rooms-select v-model="rooms" />
                    </v-col>
                    <v-col v-if="isCitizenshipMainOption" cols="12" md="2">
                        <v-select
                            v-model="selectedCitizenship"
                            background-color="white"
                            filled
                            :prepend-inner-icon="'mdi-account-tie'"
                            return-object
                            item-text="name"
                            :items="citizenshipItems"
                            :label="$t('citizenship')"
                            single-line
                            :menu-props="{bottom: true, offsetY: true}"
                            :rules="[v => !!v || `${$t('citizenship')} ${$t('validation.required')}`]"
                        />
                    </v-col>
                    <v-col class="hidden-sm-and-down" md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid || loading" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :options="['meal', 'rating', 'freeOnly', 'adultsOnly']"
                :is-citizenship-main-option="isCitizenshipMainOption"
            />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid || loading" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import HotelSearchForm from '~/components/search/forms/HotelSearchForm'
    import {hotelsRuntimeStore, runtimeStore} from '@/utils/store-accessor'
    import {appInstance} from '@/utils/app-accessor'

    @Component
    export default class HotelSearchFormMod extends HotelSearchForm {
        @Prop() productArea
        @Prop() productName

        selectedAreaCity = null
        selectedAreaCityHotel = null
        isFreeSearch = false
        loading = false
        popularDestinationsForIsrael = []
        israelHotels = []

        async created() {
            try {
                this.israelHotels = await this.$axios.$get('/israelHotels.json')
            } catch (e) {
                this.israelHotels = []
            }
        }

        async changeSelectedCity() {
            if (this.selectedAreaCity !== null) {
                try {
                    this.loading = true
                    const locations = await appInstance.$api.locations.get({
                        id: this.selectedAreaCity.cityId,
                    })

                    this.city = locations.cities[0]
                    // eslint-disable-next-line no-empty
                } catch (e) {
                } finally {
                    this.loading = false
                }
            } else {
                this.selectedAreaCityHotel = null
                this.city = {}
            }
        }

        changeSelectedHotel() {
            if (this.selectedAreaCityHotel !== null) {
                this.city = {
                    ...this.city,
                    hotelCode: this.selectedAreaCityHotel?.hotelCode,
                    cityId: this.selectedAreaCityHotel?.cityId,
                    id: this.selectedAreaCityHotel?.hotelId,
                }
            } else {
                this.city = {
                    ...this.city,
                    hotelCode: null,
                    cityId: null,
                    id: this.selectedAreaCity?.cityId,
                }
            }
        }

        newQuery() {
            const query = HotelSearchForm.options.methods.newQuery.call(this)

            if (this.productArea) {
                query.area = this.productArea?.name
            }
            if (this.$route.query.sanatoria) {
                query.sanatoria = !!this.$route.query.sanatoria
            }

            return query
        }

        async load() {
            await HotelSearchForm.options.methods.load.call(this)

            if (hotelsRuntimeStore.city.id) {
                this.selectedAreaCity = this.productAreaCities.find(e => {
                    return +e.cityId === hotelsRuntimeStore.city.id
                })
                await this.changeSelectedCity()
            }
        }

        async loadPopularDestinations() {
            try {
                this.popularDestinations = (
                    await this.$api.locations.get({limitCities: 7, productType: 'ACCOMMODATION'})
                ).cities.filter(e => e.countryId !== 15561)
                this.popularDestinationsForIsrael = (
                    await this.$api.locations.get({limitCities: 7, productType: 'ACCOMMODATION', countryId: 15561})
                ).cities
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get isNotWorldSearch() {
            return this.productArea?.name !== 'world'
        }

        get locale() {
            return this.$i18n.locale
        }

        get citizenshipItems() {
            const firstCitizenshipIds = [15561, 23677, 3854, 26452, 6880, 3868, 26476]

            const citizenship = runtimeStore.restrictedCountries.map(({id, name}) => {
                return {
                    name,
                    value: id,
                }
            })

            citizenship.sort((a, b) => {
                if (a.value !== b.value) {
                    const firstIndex = firstCitizenshipIds.indexOf(a.value)
                    const secondIndex = firstCitizenshipIds.indexOf(b.value)

                    if (firstIndex === -1) {
                        return 1
                    }

                    if (secondIndex === -1) {
                        return -1
                    }

                    return firstIndex - secondIndex
                } else {
                    return 0
                }
            })

            return citizenship
        }

        get productAreaCities() {
            if (this.productArea?.name === 'israel') {
                return this.israelHotels.map(e => {
                    const cityNames = e.cityName.split('/')

                    return {...e, cityName: this.locale === 'ru' ? cityNames[1] : cityNames[0]}
                })
            }

            return []
        }

        get productAreaCityHotels() {
            return this.israelHotels
                .filter(e => this.selectedAreaCity?.cityId === e.cityId)
                .sort((a, b) => a.name.localeCompare(b.name))
        }
    }
</script>
