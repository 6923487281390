<script>
    import {Component} from 'nuxt-property-decorator'
    import SearchSummaryLayout from '@/components/parts/SearchSummaryLayout'

    @Component({
        components: {
            SanatoriaSearchForm: () => import('@@/mods/att/components/search/forms/SanatoriaSearchForm'),
        },
    })
    export default class SearchSummaryLayoutMod extends SearchSummaryLayout {
        get productArea() {
            if (this.searchRequest.area) {
                return {name: this.searchRequest.area}
            }

            return undefined
        }
    }
</script>
