<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelsPage from '@/pages/hotels/HotelsPage'

    @Component
    export default class HotelsPageMod extends HotelsPage {
        get searchRequest() {
            const searchRequest = HotelsPage.options.computed.searchRequest.get.call(this)
            searchRequest.sanatoria = !!this.$route.query.sanatoria

            return searchRequest
        }
    }
</script>
