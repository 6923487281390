<script>
    import {Component} from 'nuxt-property-decorator'
    import OrderDetailsSummary from '~/components/account/OrderDetailsSummary'

    @Component
    export default class OrderDetailsSummaryMod extends OrderDetailsSummary {
        get isShowPaymentStatus() {
            return false
        }
    }
</script>
