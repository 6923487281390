<script>
    import {Component} from 'nuxt-property-decorator'
    import SupplierPrice from '@/components/snippets/SupplierPrice'

    @Component
    export default class SupplierPriceMod extends SupplierPrice {
        get price() {
            const convertedPrice = this.$options.filters.convertPrice(this.supplierPrice)
            convertedPrice.amount = Math.round(convertedPrice.amount)

            const {amount, currency} = convertedPrice

            return this.$options.filters.priceFormat(amount, currency)
        }
    }
</script>
