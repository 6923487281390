<template>
    <img v-if="paymentMethod === 'pelecard'" src="/dss.png" alt="DSS compliant" />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class PaymentOptionImgMod extends Vue {
        @Prop({required: true}) paymentMethod
    }
</script>

<style lang="scss" scoped>
    img {
        height: 55px;
        max-width: 120px;
    }
</style>
