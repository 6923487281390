<script>
    import {Component} from 'nuxt-property-decorator'
    import MainPageFormWrapper from '@/components/parts/MainPageFormWrapper'
    import {cmsStore} from '@/utils/store-accessor'

    @Component
    export default class MainPageFormWrapperMod extends MainPageFormWrapper {
        get backgrounds() {
            const bg = (caption, defaultBackground) =>
                this.$options.filters.srcHost(
                    cmsStore.homePageSettings.searchFormBackgrounds[caption] || defaultBackground
                )

            return {
                ...MainPageFormWrapper.options.computed.backgrounds.get.call(this),
                ATTSanatoria: bg('ATTSanatoria', '/home-bg-ATTSanatoria.jpg'),
            }
        }
    }
</script>
