<script>
    import {Component} from 'vue-property-decorator'
    import TouristsSelect from '~/components/search/forms/TouristsSelect'

    @Component
    export default class TouristsSelectMod extends TouristsSelect {
        get childrenAgeItems() {
            return Array.from(Array(12).keys())
        }
    }
</script>
