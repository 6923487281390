<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelMap from '@/components/search/offers/HotelMap'

    @Component
    export default class HotelMapMod extends HotelMap {
        clusterMarkerLabel(minPrice) {
            const convertedPrice = this.$options.filters.convertPrice(minPrice)
            convertedPrice.amount = Math.round(convertedPrice.amount)
            const {amount, currency} = convertedPrice

            return minPrice ? `${this.$t('from')} ${this.$options.filters.priceFormat(amount, currency)}` : ''
        }
    }
</script>
