<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelOfferPriceBlock from '@/components/search/offers/HotelOfferPriceBlock'

    @Component
    export default class HotelOfferPriceBlockMod extends HotelOfferPriceBlock {
        get minPrice() {
            const convertedPrice = this.$options.filters.convertPrice(this.minPriceRoomOffer.price)
            convertedPrice.amount = Math.round(convertedPrice.amount)

            const {amount, currency} = convertedPrice

            return this.$options.filters.priceFormat(amount, currency)
        }
    }
</script>
