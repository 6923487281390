<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelSearchSummary from '@/components/search/HotelSearchSummary'

    @Component
    export default class HotelSearchSummaryMod extends HotelSearchSummary {
        get searchFormComponent() {
            if (this.searchRequest.sanatoria === true) {
                return 'sanatoria-search-form'
            }

            return 'hotel-search-form'
        }
    }
</script>
