<script>
    import {Component} from 'nuxt-property-decorator'
    import BookingPageBase from '@/components/parts/BookingPageBase'

    @Component
    export default class BookingPageBaseMod extends BookingPageBase {
        async prepareBook() {
            const hotelCodesForAgeWarning = ['121']

            if (hotelCodesForAgeWarning.includes(this.offer.hotelCode)) {
                this.$toast.warning(this.$t('hotel_age_limit'))
            }

            BookingPageBase.options.methods.prepareBook.call(this)
        }
    }
</script>
