<template>
    <div>
        <v-tabs
            v-if="activeProducts.length > 1 || tabLinks.length > 0"
            ref="tabs"
            v-model="productTabs"
            show-arrows
            color="white"
            background-color="rgba(0, 0, 0, 0.6)"
            slider-color="primary"
            dark
            @change="change"
        >
            <v-tab v-for="product in activeProducts" :key="product + '_tab'">
                <v-icon class="me-1">
                    {{ products[product].ico }}
                </v-icon>
                <span class="hidden-sm-and-down">{{ $t(products[product].title) }}</span>
            </v-tab>
            <search-form-tab-link
                v-for="tab in tabLinks"
                :key="tab.name"
                :name="tab.name"
                :href="tab.href"
                :ico="tab.ico"
            />
        </v-tabs>
        <v-tabs
            v-if="productAreas.length && isHotelsActive"
            ref="areaTabs"
            v-model="activeProductAreaIndex"
            centered
            class="rounded-t"
            background-color="rgba(0, 0, 0, 0.6)"
            color="white"
            slider-color="primary"
            dark
        >
            <v-tab v-for="(area, index) in productAreas" :key="area.name + '_tab'">
                <v-icon class="me-2">
                    {{ productAreas[index].ico }}
                </v-icon>
                <span class="hidden-sm-and-down"> {{ `${$t('search')} ${$t(`search_form.${area.name}`)} ` }}</span>
            </v-tab>
        </v-tabs>
        <choose-agent-dialog v-if="selectAgentEnabled && !selectedAgent && chooseAgent" />
        <v-tabs-items v-model="productTabs" class="overflow--visible">
            <v-tab-item v-for="(product, index) in activeProducts" :key="index">
                <component
                    :is="products[product].form"
                    :variant="products[product].variant"
                    :product-name="products[product].title"
                    :city-id="cityId"
                    :product-area="activeProductArea"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import {Component, Watch} from 'nuxt-property-decorator'
    import SearchFormTabs from '@/components/SearchFormTabs'
    import SanatoriaSearchForm from '@@/mods/att/components/search/forms/SanatoriaSearchForm'
    import ChooseAgentDialog from '@/components/ChooseAgentDialog'
    import {authStore, persistentStore} from '@/utils/store-accessor'

    @Component({
        components: {
            ChooseAgentDialog,
            SanatoriaSearchForm,
        },
    })
    export default class SearchFormTabsMod extends SearchFormTabs {
        productTabs = 0
        activeProductAreaIndex = 0

        @Watch('locale')
        onLocaleChange() {
            this.$refs.areaTabs.onResize()
            this.$refs.tabs.onResize()
        }

        get activeProductArea() {
            if (this.isSanatoriaActive) {
                return this.productAreas.find(a => a.name === 'world')
            }

            return this.productAreas[this.activeProductAreaIndex]
        }

        get isSanatoriaActive() {
            return Object.keys(this.products)[this.productTabs] === 'ATTSanatoria'
        }

        get isHotelsActive() {
            return Object.keys(this.products)[this.productTabs] === 'hotels'
        }

        get productAreas() {
            return [
                {name: 'israel', ico: 'mdi-star-david'},
                {name: 'world', ico: 'mdi-earth'},
            ]
        }

        get locale() {
            return this.$i18n.locale
        }

        get tabLinks() {
            return [
                {
                    name: 'att_tab_links.transfers_israel',
                    ico: 'mdi-bus',
                    href:
                        this.locale === 'ru'
                            ? '//atlantistravel.co.il/transfers/'
                            : '//atlantistravel.co.il/en/transfers/',
                },
                {
                    name: 'att_tab_links.excursions',
                    ico: 'mdi-lightning-bolt-outline',
                    href:
                        this.locale === 'ru'
                            ? '//atlantistravel.co.il/ekskursii/'
                            : '//atlantistravel.co.il/en/ekskursii/',
                },
            ]
        }

        get products() {
            const originalProducts = SearchFormTabs.options.computed.products.get.call(this)

            return {
                ...originalProducts,
                hotels: {
                    ico: 'mdi-office-building',
                    title: 'hotels',
                    form: 'hotel-search-form',
                },
                ATTSanatoria: {
                    ico: 'mdi-hospital-box-outline',
                    title: 'sanatoria',
                    form: 'sanatoria-search-form',
                },
                transfers: {
                    ico: 'mdi-bus',
                    title: 'transfers.transfers',
                    form: 'transfer-search-form',
                    'data-cy': 'transfer-search-form',
                },
                activities: {
                    ico: 'mdi-lightning-bolt-outline',
                    title: 'activities.activities',
                    form: 'activity-search-form',
                    'data-cy': 'activity-search-form',
                },
                flights: {
                    ico: 'mdi-airplane',
                    title: 'flights.flights',
                    form: 'flight-search-form',
                    'data-cy': 'flight-search-form',
                },
                tours: {
                    ico: 'mdi-wallet-travel',
                    title: 'tours.tours',
                    form: 'tour-search-form',
                    'data-cy': 'packages-search-form',
                },
            }
        }

        get isTO1() {
            return authStore.isTO1
        }

        get selectedAgent() {
            return persistentStore.selectedAgent
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .theme--light.v-tabs-items {
            background-color: transparent;
        }

        .v-card.main-form {
            width: auto;

            &.theme--light.v-card.v-sheet--outlined {
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }
    }
</style>
