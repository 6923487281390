<script>
    import {Component} from 'nuxt-property-decorator'
    import ConditionsLabel from '@/components/snippets/ConditionsLabel'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class ConditionsLabelMod extends ConditionsLabel {
        get color() {
            switch (this.status) {
                case 'isNonRefundable':
                    return 'red--text'
                default:
                    return 'warning--text'
            }
        }

        get label() {
            if (hotelsRuntimeStore.city.countryId === this.$config.att_israelCountryId) {
                switch (this.status) {
                    case 'isNonRefundable':
                        return this.$t('non_refundable')
                    default:
                        return this.$t('cancellation_policy')
                }
            } else {
                switch (this.status) {
                    case 'isLoading':
                    case 'hasErrors':
                        return this.$t('cancellation_policy')
                    case 'isNonRefundable':
                        return this.$t('non_refundable')
                    case 'isFree':
                        return this.isFreeLabel
                    case 'freeDate':
                        return this.$t('free_cancellation_before_date', {
                            date: this.$options.filters.dateFormat(this.freeDate),
                        })
                    default:
                        return this.$t('special_conditions')
                }
            }
        }
    }
</script>
