<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import CityHotelAutocomplete from '~/components/search/forms/CityHotelAutocomplete'

    @Component
    export default class CityHotelAutocompleteMod extends CityHotelAutocomplete {
        @Prop() excludeCountryId

        async initSearch(val) {
            await CityHotelAutocomplete.options.methods.initSearch.call(this, val)

            if (this.entries.length === 0) {
                this.tryResearch.status = true
                this.tryResearch.message = this.$t('try_rewrite_destination')
            }
        }

        async searchHotels(val) {
            const rs = await this.$api.suggestHotels.get({
                pattern: val,
                limit: 10,
                locationId: this.countryId,
            })

            const hotels = this.mapHotels(rs.hotels)

            if (this.excludeCountryId) {
                return hotels?.filter(e => e.countryId !== this.excludeCountryId)
            }

            return hotels
        }

        async searchCities(val) {
            const cities = await CityHotelAutocomplete.options.methods.searchCities.call(this, val)

            if (this.excludeCountryId) {
                return cities.filter(e => e.countryId !== this.excludeCountryId)
            }

            return cities
        }
    }
</script>
