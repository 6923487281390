<template>
    <v-tooltip top>
        <template v-slot:activator="{on}">
            <div v-on="on">
                <v-rating
                    :empty-icon="icon"
                    :full-icon="icon"
                    :half-icon="halfIcon"
                    :value="rating"
                    background-color="grey lighten-2"
                    readonly
                    color="primary"
                    half-increments
                    small
                    dense
                />
            </div>
        </template>
        <span>{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelStars from '@/components/hotels/snippets/HotelStars'

    @Component
    export default class HotelStarsMod extends HotelStars {
        get rating() {
            const index = this.hotel.ratings?.findIndex(rating => rating.ratingSource === 'INTERNAL')
            if (index >= 0) return this.hotel.ratings[index].rating

            return this.hotel.category
        }

        get icon() {
            return this.hotel.ratings?.find(rating => rating.ratingSource === 'INTERNAL') ? 'mdi-circle' : 'mdi-star'
        }

        get halfIcon() {
            return this.hotel.ratings?.find(rating => rating.ratingSource === 'INTERNAL')
                ? 'mdi-circle-half-full'
                : 'mdi-star-half-full'
        }

        get tooltipText() {
            return this.hotel.ratings?.find(rating => rating.ratingSource === 'INTERNAL')
                ? this.$t('hotel_ratings.circle')
                : this.$t('hotel_ratings.star')
        }
    }
</script>
