<script>
    import HotelSearchForm from '~src/components/search/forms/hotelSearchForm.src'
    import {Component} from 'nuxt-property-decorator'

    @Component
    export default class SanatoriaSearchForm extends HotelSearchForm {
        newQuery() {
            const query = HotelSearchForm.options.methods.newQuery.call(this)
            query.sanatoria = true

            return query
        }

        get isNotWorldSearch() {
            return false
        }
    }
</script>
