<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelRoomOfferPriceBlock from '@/components/search/offers/HotelRoomOfferPriceBlock'

    @Component
    export default class HotelRoomOfferPriceBlockMod extends HotelRoomOfferPriceBlock {
        get btnText() {
            const convertedPrice = this.$options.filters.convertPrice(this.room.price)
            convertedPrice.amount = Math.round(convertedPrice.amount)

            const {amount, currency} = convertedPrice

            return this.$options.filters.priceFormat(amount, currency)
        }
    }
</script>
