<template>
    <div v-if="!isB2B" :class="[statusColor]">
        {{ text }}
    </div>
</template>
<script>
    import {Component} from 'nuxt-property-decorator'
    import PaymentStatusLabel from '~/components/account/snippets/PaymentStatusLabel'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class PaymentStatusLabelMod extends PaymentStatusLabel {
        get isB2B() {
            return authStore.hasCorporateAccess
        }
    }
</script>
