<script>
    import {Component} from 'nuxt-property-decorator'
    import SpecialOfferLabel from '@/components/hotels/search/offers/SpecialOfferLabel'

    @Component
    export default class SpecialOfferLabelMod extends SpecialOfferLabel {
        get specialOfferChipColor() {
            return 'primary'
        }
    }
</script>
